import React from 'react';

const Footer = () => {
  return (
    <footer className="max-w-md pb-16 text-sm text-slate sm:pb-0">
      <p>
        Inspired by{' '}
        <a
          href="https://brittanychiang.com/#projects"
          className="font-medium text-light-slate hover:text-gold"
          target="_blank"
          rel="noreferrer noopener"
        >
          Brittany Chaing
        </a>{' '}
        and coded in{' '}
        <a
          href="https://cursor.com"
          className="font-medium text-light-slate hover:text-gold"
          target="_blank"
          rel="noreferrer noopener"
        >
          Cursor
        </a>
        . Built with{' '}
        <a
          href="https://reactjs.org/"
          className="font-medium text-light-slate hover:text-gold"
          target="_blank"
          rel="noreferrer noopener"
        >
          React
        </a>{' '}
        and{' '}
        <a
          href="https://tailwindcss.com/"
          className="font-medium text-light-slate hover:text-gold"
          target="_blank"
          rel="noreferrer noopener"
        >
          Tailwind CSS
        </a>
        , deployed with{' '}
        <a
          href="https://vercel.com/"
          className="font-medium text-light-slate hover:text-gold"
          target="_blank"
          rel="noreferrer noopener"
        >
          Vercel.
        </a>
      </p>
    </footer>
  );
};

export default Footer;
