import React from 'react';
import SocialLinks from './SocialLinks';

const Header = ({ activeSection }) => {
  return (
    <header className="lg:sticky lg:top-0 lg:flex lg:max-h-screen lg:w-1/2 lg:flex-col lg:justify-between lg:py-24">
      <div>
        <div className="flex items-center gap-4 mb-4">
          <img
            src="/clay-profile.jpg"
            alt="Clay Reimus"
            className="w-16 h-16 rounded-full object-cover border-2 border-gold shadow-md"
          />
          <h1 className="text-4xl font-bold tracking-tight text-lightest-slate sm:text-5xl">
            <a href="/">Clay Reimus</a>
          </h1>
        </div>
        <h2 className="mt-3 text-lg font-medium tracking-tight text-lightest-slate sm:text-xl">
          Head of Growth
        </h2>
        <p className="mt-4 max-w-xs leading-normal">
          Marketing professional and curious technologist.
        </p>

        <a
          href="mailto:hello@clayreimus.com"
          className="mt-6 inline-block rounded-md bg-gold px-4 py-2 font-medium text-dark-navy 
          border-2 border-transparent hover:bg-dark-navy hover:text-gold hover:border-gold 
          transition-all duration-300 hover:shadow-[0_0_15px_rgba(253,184,51,0.5)]"
        >
          Email Me
        </a>

        <nav className="nav hidden lg:block" aria-label="In-page jump links">
          <ul className="mt-16 w-max">
            <li>
              <a
                className={`group flex items-center py-3 ${
                  activeSection === 'about' ? 'active' : ''
                }`}
                href="#about"
              >
                <span
                  className={`nav-indicator mr-4 ${
                    activeSection === 'about'
                      ? 'bg-light-slate'
                      : 'bg-light-navy'
                  }`}
                ></span>
                <span
                  className={`text-xs font-bold uppercase tracking-widest ${
                    activeSection === 'about'
                      ? 'text-lightest-slate'
                      : 'text-slate'
                  }`}
                >
                  About
                </span>
              </a>
            </li>
            <li>
              <a
                className={`group flex items-center py-3 ${
                  activeSection === 'experience' ? 'active' : ''
                }`}
                href="#experience"
              >
                <span
                  className={`nav-indicator mr-4 ${
                    activeSection === 'experience'
                      ? 'bg-light-slate'
                      : 'bg-light-navy'
                  }`}
                ></span>
                <span
                  className={`text-xs font-bold uppercase tracking-widest ${
                    activeSection === 'experience'
                      ? 'text-lightest-slate'
                      : 'text-slate'
                  }`}
                >
                  Experience
                </span>
              </a>
            </li>
            <li>
              <a
                className={`group flex items-center py-3 ${
                  activeSection === 'skills' ? 'active' : ''
                }`}
                href="#skills"
              >
                <span
                  className={`nav-indicator mr-4 ${
                    activeSection === 'skills'
                      ? 'bg-light-slate'
                      : 'bg-light-navy'
                  }`}
                ></span>
                <span
                  className={`text-xs font-bold uppercase tracking-widest ${
                    activeSection === 'skills'
                      ? 'text-lightest-slate'
                      : 'text-slate'
                  }`}
                >
                  Skills
                </span>
              </a>
            </li>
            <li>
              <a
                className={`group flex items-center py-3 ${
                  activeSection === 'projects' ? 'active' : ''
                }`}
                href="#projects"
              >
                <span
                  className={`nav-indicator mr-4 ${
                    activeSection === 'projects'
                      ? 'bg-light-slate'
                      : 'bg-light-navy'
                  }`}
                ></span>
                <span
                  className={`text-xs font-bold uppercase tracking-widest ${
                    activeSection === 'projects'
                      ? 'text-lightest-slate'
                      : 'text-slate'
                  }`}
                >
                  Side Projects
                </span>
              </a>
            </li>
          </ul>
        </nav>

        <div className="mt-8 block lg:hidden">
          <SocialLinks />
        </div>
      </div>

      <div className="hidden lg:block">
        <SocialLinks />
      </div>
    </header>
  );
};

export default Header;
