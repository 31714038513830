import React from 'react';
import SectionHeader from './SectionHeader';

// Skill proficiency levels
const LEVELS = {
  EXPERT: 'Expert',
  ADVANCED: 'Advanced',
  PROFICIENT: 'Proficient',
  FAMILIAR: 'Familiar',
};

const skillsData = [
  {
    id: 1,
    category: 'Acquisition',
    skills: [
      { name: 'SEO', level: LEVELS.EXPERT },
      { name: 'Product-Led', level: LEVELS.EXPERT },
      { name: 'Paid Search', level: LEVELS.EXPERT },
      { name: 'Paid Social', level: LEVELS.ADVANCED },
      { name: 'Organic Social', level: LEVELS.ADVANCED },
      { name: 'Affiliates', level: LEVELS.ADVANCED },
      { name: 'OOH / Print', level: LEVELS.PROFICIENT },
    ],
  },
  {
    id: 2,
    category: 'Retention',
    skills: [
      { name: 'Churn Mitigation', level: LEVELS.EXPERT },
      { name: 'Email Marketing', level: LEVELS.EXPERT },
      { name: 'User Research', level: LEVELS.ADVANCED },
      { name: 'Save Offers', level: LEVELS.ADVANCED },
      { name: 'Gamification', level: LEVELS.ADVANCED },
      { name: 'Lifecycle Content', level: LEVELS.PROFICIENT },
      { name: 'Loyalty Programs', level: LEVELS.PROFICIENT },
    ],
  },
  {
    id: 3,
    category: 'Lifecycle',
    skills: [
      { name: 'CLV Upsell', level: LEVELS.EXPERT },
      { name: 'Pricing Strategy', level: LEVELS.EXPERT },
      { name: 'Strategic Sales', level: LEVELS.ADVANCED },
      { name: 'Referral Programs', level: LEVELS.PROFICIENT },
      { name: 'Cross-Sell', level: LEVELS.PROFICIENT },
      { name: 'CS Feedback Loop', level: LEVELS.FAMILIAR },
      { name: 'Personalization', level: LEVELS.FAMILIAR },
    ],
  },
  {
    id: 4,
    category: 'Analytics',
    skills: [
      { name: 'Forecasting', level: LEVELS.EXPERT },
      { name: 'Budgeting', level: LEVELS.EXPERT },
      { name: 'SEO / Paid Search', level: LEVELS.EXPERT },
      { name: 'Attribution Modeling', level: LEVELS.ADVANCED },
      { name: 'User Behavior', level: LEVELS.ADVANCED },
      { name: 'Funnel Analytics', level: LEVELS.ADVANCED },
      { name: 'Dashboards', level: LEVELS.PROFICIENT },
    ],
  },
  {
    id: 5,
    category: 'Tools & Tech',
    skills: [
      { name: 'GA4, Mixpanel', level: LEVELS.EXPERT },
      { name: 'Optimizely', level: LEVELS.EXPERT },
      { name: 'AI', level: LEVELS.ADVANCED },
      { name: 'SQL', level: LEVELS.ADVANCED },
      { name: 'HTML/CSS/JS', level: LEVELS.PROFICIENT },
      { name: 'Tableau, Looker', level: LEVELS.PROFICIENT },
      { name: 'CRM & Automation', level: LEVELS.PROFICIENT },
    ],
  },
];

// Component to render a skill bar with proficiency level
const SkillBar = ({ skill }) => {
  // Map proficiency levels to numerical values for the progress bar
  const levelValues = {
    [LEVELS.EXPERT]: 100,
    [LEVELS.ADVANCED]: 75,
    [LEVELS.PROFICIENT]: 50,
    [LEVELS.FAMILIAR]: 25,
  };

  // Get the corresponding width percentage
  const widthPercentage = levelValues[skill.level];

  // Generate color based on proficiency level
  const getColorClass = (level) => {
    switch (level) {
      case LEVELS.EXPERT:
        return 'bg-gold'; // Gold color
      case LEVELS.ADVANCED:
        return 'bg-gold/75';
      case LEVELS.PROFICIENT:
        return 'bg-gold/50';
      case LEVELS.FAMILIAR:
        return 'bg-gold/25';
      default:
        return 'bg-gold/75';
    }
  };

  return (
    <div className="mb-3">
      <div className="flex justify-between items-center mb-1">
        <span className="text-sm font-medium text-light-slate">
          {skill.name}
        </span>
      </div>
      <div className="w-full bg-light-navy rounded-full h-2.5 overflow-hidden">
        <div
          className={`h-2.5 rounded-full ${getColorClass(
            skill.level
          )} transform transition-all duration-700 ease-out animate-skillBar`}
          style={{
            width: `${widthPercentage}%`,
            transform: 'translateX(-100%)',
            animation: `skillBarSlide 0.8s ease-out forwards`,
          }}
        ></div>
      </div>
    </div>
  );
};

// Component to render a skill category
const SkillCategory = ({ category }) => {
  return (
    <div className="bg-navy rounded-lg border border-light-navy p-5 mb-4">
      <h3 className="text-lg font-bold text-lightest-slate mb-3">
        {category.category}
      </h3>
      <div>
        {category.skills.map((skill, index) => (
          <SkillBar key={index} skill={skill} />
        ))}
      </div>
    </div>
  );
};

const Skills = () => {
  return (
    <section
      id="skills"
      className="content-section pt-2 pb-6"
      aria-label="Professional skills"
    >
      <SectionHeader id="skills" title="Skills" />

      <div className="mt-3 mb-4 text-sm text-slate">
        <div className="flex flex-wrap items-center gap-4 md:gap-6">
          <div className="flex items-center">
            <div className="w-3 h-3 rounded-full bg-gold mr-2"></div>
            <span>Expert</span>
          </div>
          <div className="flex items-center">
            <div className="w-3 h-3 rounded-full bg-gold/75 mr-2"></div>
            <span>Advanced</span>
          </div>
          <div className="flex items-center">
            <div className="w-3 h-3 rounded-full bg-gold/50 mr-2"></div>
            <span>Proficient</span>
          </div>
          <div className="flex items-center">
            <div className="w-3 h-3 rounded-full bg-gold/25 mr-2"></div>
            <span>Familiar</span>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4 mt-4">
        {skillsData.slice(0, 3).map((skillCategory) => (
          <div key={skillCategory.id} className="lg:col-span-2">
            <SkillCategory category={skillCategory} />
          </div>
        ))}
        {skillsData.slice(3, 5).map((skillCategory) => (
          <div key={skillCategory.id} className="lg:col-span-3">
            <SkillCategory category={skillCategory} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
