import React from 'react';
import ExperienceItem from './ExperienceItem';
import SectionHeader from './SectionHeader';

const experienceData = [
  {
    id: 1,
    date: '2021 — Now',
    title: 'Head of Marketing & Growth',
    company: 'Roll20',
    companyUrl: 'https://roll20.net',
    description: [
      'Drove 300k+ user growth by identifying and implementing new inbound channels',
      'Generated $1M+ in incremental ARR by overhauling retention and winback tactics',
      'Partnered with CEO on go-to-market strategies for M&A, new product launches',
    ],
    keyMetrics: [
      { value: '300k+', label: 'User Growth' },
      { value: '$1M+', label: 'Incremental ARR' },
      { value: '25%', label: 'Retention Increase' },
    ],
  },
  {
    id: 2,
    date: '2020 — 2021',
    title: 'Senior Director of Performance Marketing',
    company: 'Kinship',
    companyUrl: 'https://kinship.co',
    description: [
      'Managed a $40M budget across five pet tech companies, increasing sales by 10%',
      'Added $2M+ in annual revenue through strategic budget allocation',
      'Reduced blended CAC by 10% through data-driven channel optimization',
    ],
    keyMetrics: [
      { value: '$40M', label: 'Budget Managed' },
      { value: '$2M+', label: 'Added Revenue' },
      { value: '10%', label: 'CAC Reduction' },
    ],
  },
  {
    id: 3,
    date: '2018 — 2020',
    title: 'Director, Customer Acquisition & SEO',
    company: 'ClassPass',
    companyUrl: 'https://classpass.com',
    description: [
      'Overhauled SEO, resulting in a 50% channel subscriber increase ($500K+ ARR)',
      'Reduced paid search CAC by 20% and social CAC by 10% while maintaining volume',
      'Consistently surpassed OKRs in referral programs and paid channels',
    ],
    keyMetrics: [
      { value: '50%', label: 'SEO Growth' },
      { value: '$500K+', label: 'Added ARR' },
      { value: '20%', label: 'CAC Reduction' },
    ],
  },
  {
    id: 4,
    date: '2015 — 2018',
    title: 'Director, SEO',
    company: 'Huge',
    companyUrl: 'https://www.hugeinc.com',
    description: [
      'Achieved a 2.5x increase in high-converting traffic and 80% growth in mobile user engagement',
      'Generated $4M+ in new business revenue through innovative strategies',
      'Created successful App Store Optimization (ASO) service offering',
    ],
    keyMetrics: [
      { value: '2.5x', label: 'Traffic Growth' },
      { value: '$4M+', label: 'New Business' },
      { value: '80%', label: 'Mobile Growth' },
    ],
  },
];

const Experience = () => {
  return (
    <section
      id="experience"
      className="content-section pt-4 pb-2 sticky-section"
      aria-label="Work experience"
    >
      <SectionHeader id="experience" title="Experience" />

      <div>
        <ul className="group/list">
          {experienceData.map((experience, index) => (
            <ExperienceItem key={index} experience={experience} />
          ))}
        </ul>

        <div className="mt-12">
          <a
            className="inline-flex items-center text-lightest-slate font-semibold group/link hover:text-gold"
            href="public/Clay-Reimus-2025.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            <span>View Full Résumé</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="ml-1 h-4 w-4 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Experience;
