import React, { useEffect, useRef, useState } from 'react';

const LatticeworkBackground = () => {
  const canvasRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Handle resize
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Handle mouse movement
  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Draw the latticework
  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Set canvas dimensions
    canvas.width = dimensions.width;
    canvas.height = dimensions.height;

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Base grid size
    const primaryGridSize = 40;
    const secondaryGridSize = primaryGridSize / 2;
    const tertiaryGridSize = primaryGridSize / 4;
    const baseLineWidth = 0.5;

    // Calculate distance from mouse to determine "raised" effect
    const getIntensity = (x, y) => {
      const dx = mousePosition.x - x;
      const dy = mousePosition.y - y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const maxDistance = 250;
      return Math.max(0, 1 - distance / maxDistance);
    };

    const drawLine = (
      x1,
      y1,
      x2,
      y2,
      baseOpacity = 0.05,
      widthMultiplier = 1
    ) => {
      const midX = (x1 + x2) / 2;
      const midY = (y1 + y2) / 2;

      const intensity = getIntensity(midX, midY);

      ctx.strokeStyle = `rgba(253, 184, 51, ${baseOpacity + intensity * 0.15})`;
      ctx.lineWidth = baseLineWidth * widthMultiplier + intensity * 1;

      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
    };

    const drawCircle = (x, y, radius) => {
      const intensity = getIntensity(x, y);

      ctx.strokeStyle = `rgba(253, 184, 51, ${0.03 + intensity * 0.12})`;
      ctx.lineWidth = baseLineWidth + intensity * 0.8;

      ctx.beginPath();
      ctx.arc(x, y, radius, 0, Math.PI * 2);
      ctx.stroke();
    };

    const drawDot = (x, y) => {
      const intensity = getIntensity(x, y);

      if (intensity > 0.1) {
        ctx.fillStyle = `rgba(253, 184, 51, ${intensity * 0.2})`;
        ctx.beginPath();
        ctx.arc(x, y, 1 + intensity * 2, 0, Math.PI * 2);
        ctx.fill();
      }
    };

    // Primary grid - main structure
    for (let y = 0; y < canvas.height; y += primaryGridSize) {
      for (let x = 0; x < canvas.width; x += primaryGridSize) {
        // Horizontal and vertical lines
        drawLine(x, y, x + primaryGridSize, y, 0.05, 1);
        drawLine(x, y, x, y + primaryGridSize, 0.05, 1);

        // Intersection dots
        drawDot(x, y);
      }
    }

    // Secondary grid - diagonal lines
    for (let y = 0; y < canvas.height; y += primaryGridSize) {
      for (let x = 0; x < canvas.width; x += primaryGridSize) {
        // Diagonal lines
        drawLine(x, y, x + primaryGridSize, y + primaryGridSize, 0.03, 0.8);
        drawLine(x + primaryGridSize, y, x, y + primaryGridSize, 0.03, 0.8);
      }
    }

    // Tertiary details - smaller grid elements
    for (let y = 0; y < canvas.height; y += secondaryGridSize) {
      for (let x = 0; x < canvas.width; x += secondaryGridSize) {
        if ((x / secondaryGridSize + y / secondaryGridSize) % 2 === 0) {
          drawLine(
            x + secondaryGridSize / 2,
            y,
            x + secondaryGridSize / 2,
            y + secondaryGridSize,
            0.02,
            0.5
          );

          drawLine(
            x,
            y + secondaryGridSize / 2,
            x + secondaryGridSize,
            y + secondaryGridSize / 2,
            0.02,
            0.5
          );
        }
      }
    }

    // Circular elements at major intersections
    for (let y = 0; y < canvas.height; y += primaryGridSize * 2) {
      for (let x = 0; x < canvas.width; x += primaryGridSize * 2) {
        drawCircle(x, y, primaryGridSize / 4);
        drawCircle(x, y, primaryGridSize / 8);
      }
    }

    // Hexagonal pattern overlay
    const hexSize = primaryGridSize * 1.5;
    const hexHeight = hexSize * Math.sqrt(3);

    for (let row = 0; row < canvas.height / hexHeight + 1; row++) {
      for (let col = 0; col < canvas.width / hexSize + 1; col++) {
        const centerX = col * hexSize * 1.5;
        const centerY = row * hexHeight + (col % 2 === 0 ? 0 : hexHeight / 2);

        if ((row + col) % 3 === 0) {
          // Draw hexagon
          for (let i = 0; i < 6; i++) {
            const angle1 = (Math.PI / 3) * i;
            const angle2 = (Math.PI / 3) * ((i + 1) % 6);

            const x1 = centerX + (hexSize / 2) * Math.cos(angle1);
            const y1 = centerY + (hexSize / 2) * Math.sin(angle1);
            const x2 = centerX + (hexSize / 2) * Math.cos(angle2);
            const y2 = centerY + (hexSize / 2) * Math.sin(angle2);

            drawLine(x1, y1, x2, y2, 0.02, 0.7);
          }
        }
      }
    }
  }, [dimensions, mousePosition]);

  return (
    <canvas
      ref={canvasRef}
      className="pointer-events-none fixed inset-0 z-0 opacity-40"
    />
  );
};

export default LatticeworkBackground;
