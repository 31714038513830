import React from 'react';
import ProjectItem from './ProjectItem';
import SectionHeader from './SectionHeader';

const projectsData = [
  {
    id: 1,
    title: 'Overlay',
    description:
      'A live sports commentary platform that lets fans choose their preferred commentators. Features include audio broadcasting, a soundboard, listener chat, and game clock / ESPN API synchronization. Currently focused on NBA and NFL games.',
    imageUrl: '/project1.jpg',
    projectUrl: 'https://overlayaudio.com/',
    technologies: ['React', 'Tailwind', 'Supabase', 'Cursor AI'],
  },
  {
    id: 2,
    title: 'Vinyl Trader',
    description:
      "A one-week game jam project inspired by the MS-DOS classic 'dope wars.' A 24-hour race to arbitrage record prices.",
    imageUrl: '/projectvinyl.png',
    projectUrl: 'https://vinyl-trader.vercel.app/',
    technologies: ['React', 'Tailwind', 'Supabase', 'Cursor AI'],
  },
  {
    id: 3,
    title: 'ConsoleSpy for Cursor',
    description:
      'An MCP server (and browser extension) that sends console logs directly to an AI IDE like Claude or Cursor. Removes a lot of annoying copying and pasting.',
    imageUrl: '/project4.png',
    projectUrl: 'https://github.com/mgsrevolver/consolespy',
    technologies: ['MCP', 'Extensions', 'Dev Tools'],
  },
  {
    id: 4,
    title: 'Kelly Criterion Calculator',
    description:
      'A simple calculator that helps sports bettors determine the optimal bet size based on their edge and bankroll. My first web development project, built in 2021.',
    imageUrl: '/project2.jpg',
    projectUrl: 'https://mgsrevolver.github.io/kellycalc/',
    technologies: ['JavaScript', 'Vue.js', 'React'],
  },
  {
    id: 5,
    title: 'Photography',
    description:
      'A new hobby I just picked up in 2025! I mostly shoot urban landscapes and architecture and edit in Lightroom.',
    imageUrl: '/project3.jpg',
    projectUrl: 'https://adobe.ly/3EQF8s4',
    technologies: ['Sony a6700', 'Sigma 18-50mm', 'Lightroom'],
  },
];

// ... existing code ...

const Projects = () => {
  return (
    <section
      id="projects"
      className="content-section pt-4 pb-2 sticky-section"
      aria-label="Selected projects"
    >
      <SectionHeader id="projects" title="Side Projects" />

      <div>
        <ul className="group/list">
          {projectsData.map((project) => (
            <ProjectItem key={project.id} project={project} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Projects;
