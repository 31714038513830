import React from 'react';
import SectionHeader from './SectionHeader';

const About = () => {
  return (
    <section
      id="about"
      className="content-section !mb-8 md:!mb-12 lg:!mb-16 sticky-section"
      aria-label="About me"
    >
      <SectionHeader id="about" title="About" />

      <div>
        <p className="mb-4">
          I'm a growth marketing executive with 12+ years of experience driving
          user growth and revenue for software companies. I build
          high-performing teams and execute across the entire marketing stack.
        </p>

        <p className="mb-4">
          Currently, I'm the Head of Marketing & Growth at{' '}
          <a
            className="font-medium text-lightest-slate hover:text-gold"
            href="https://roll20.net"
            target="_blank"
            rel="noreferrer noopener"
          >
            Roll20
          </a>
          , where I lead user acquisition, retention, and revenue growth, and
          report to the CEO.
        </p>

        <p className="mb-4">
          In the past, I've had the opportunity to drive (and measure) growth in
          various settings — from{' '}
          <a
            className="font-medium text-lightest-slate hover:text-gold"
            href="https://classpass.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            fast-growing unicorns
          </a>{' '}
          to{' '}
          <a
            className="font-medium text-lightest-slate hover:text-gold"
            href="https://www.hugeinc.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            global digital agencies
          </a>{' '}
          and{' '}
          <a
            className="font-medium text-lightest-slate hover:text-gold"
            href="https://www.kinship.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            large corporations
          </a>
          . I'm efficient, adaptable, and always eager to lead by example and
          get my hands dirty.
        </p>

        <p>
          I'm also an amateur photographer, game developer, and father of two.
        </p>
      </div>
    </section>
  );
};

export default About;
