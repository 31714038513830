import React, { useEffect, useRef, useState } from 'react';

const SectionHeader = ({ id, title }) => {
  const [isActive, setIsActive] = useState(false);
  const headerRef = useRef(null);
  const sectionRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
    // Get the parent section element
    if (headerRef.current) {
      sectionRef.current = headerRef.current.closest(`section#${id}`);
    }

    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: [0, 0.25, 0.5, 0.75, 1.0], // Multiple thresholds to track visibility percentage
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        // Check if the section is at least 25% visible (lower threshold for mobile)
        if (entry.intersectionRatio >= 0.25) {
          setIsActive(true);

          // Update active state in navigation if it exists
          const navLink = document.querySelector(`.nav-link[href="#${id}"]`);
          if (navLink) {
            document.querySelectorAll('.nav-link').forEach((link) => {
              link.classList.remove('active-nav');
            });
            navLink.classList.add('active-nav');
          }
        } else {
          setIsActive(false);
        }
      });
    };

    // Create the observer
    const observer = new IntersectionObserver(handleIntersection, options);

    // Start observing
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Also check if this section matches the current hash
    const checkHash = () => {
      if (window.location.hash === `#${id}`) {
        setIsActive(true);
      }
    };

    // Add scroll event listener for mobile devices
    const handleScroll = () => {
      if (!sectionRef.current) return;

      const rect = sectionRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Calculate how much of the section is visible
      const visibleHeight =
        Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
      const visibleRatio = visibleHeight / rect.height;

      // Activate if at least 25% visible
      if (visibleRatio >= 0.25) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    window.addEventListener('hashchange', checkHash);

    // Check on initial load
    checkHash();
    handleScroll();

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
      window.removeEventListener('hashchange', checkHash);
    };
  }, [id]);

  // Effect to animate the line when active state changes
  useEffect(() => {
    if (isActive && lineRef.current) {
      // Reset the line width to 0 before animating
      lineRef.current.style.width = '0';

      // Force a reflow to ensure the reset takes effect
      void lineRef.current.offsetWidth;

      // Animate to full width
      lineRef.current.style.width = '100%';
    }
  }, [isActive]);

  return (
    <div ref={headerRef} className="section-heading">
      <div className="flex items-center">
        <span
          className={`mr-4 transition-colors duration-300 ${
            isActive ? 'text-gold' : 'text-gray-500'
          }`}
        >
          —
        </span>
        <h2
          className={`section-title transition-colors duration-300 ${
            isActive ? 'text-white' : 'text-gray-400'
          }`}
        >
          {title}
        </h2>
        <div className="ml-4 h-px flex-grow relative overflow-hidden">
          {isActive && (
            <div
              ref={lineRef}
              className="absolute top-0 left-0 h-full bg-gold transition-all duration-700 ease-out"
              style={{ width: '0' }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionHeader;
