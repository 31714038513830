import React from 'react';

const ExperienceItem = ({ experience }) => {
  const { date, title, company, companyUrl, description, keyMetrics } =
    experience;

  return (
    <li className="mb-12">
      <div className="group relative grid pb-1 transition-all sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
        <div className="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition lg:-inset-x-6 lg:block lg:group-hover:bg-light-navy/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>

        <div className="z-10">
          <h3 className="font-medium leading-snug text-lightest-slate">
            <div className="flex items-center justify-between flex-wrap">
              <a
                className="inline-flex items-baseline font-medium leading-tight text-lightest-slate hover:text-gold group/link"
                href={companyUrl}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block"></span>
                <span>
                  {title} · {company}{' '}
                  <span className="inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
              </a>
              <span className="text-xs font-semibold uppercase tracking-wide text-slate">
                {date}
              </span>
            </div>
          </h3>

          <ul className="mt-2 text-sm leading-normal list-disc pl-4 space-y-2">
            {Array.isArray(description) ? (
              description.map((bullet, index) => (
                <li key={index} className="text-slate">
                  {bullet}
                </li>
              ))
            ) : (
              <li className="text-slate">{description}</li>
            )}
          </ul>

          <div className="mt-4 flex flex-wrap">
            {keyMetrics &&
              keyMetrics.map((metric, index) => (
                <div
                  key={index}
                  className="mr-6 mb-3 min-w-[80px] max-w-[110px]"
                >
                  <div className="text-gold font-semibold text-base whitespace-nowrap">
                    {metric.value}
                  </div>
                  <div className="text-xs text-light-slate whitespace-nowrap">
                    {metric.label}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </li>
  );
};

export default ExperienceItem;
