import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import About from './components/About';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Footer from './components/Footer';
import MouseGradient from './components/MouseGradient';
import LatticeworkBackground from './components/LatticeworkBackground';
import Skills from './components/Skills';

function App() {
  const [activeSection, setActiveSection] = useState('about');

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['about', 'experience', 'skills', 'projects'];

      const current =
        sections.find((section) => {
          const element = document.getElementById(section);
          if (element) {
            const rect = element.getBoundingClientRect();
            return rect.top <= 100 && rect.bottom >= 100;
          }
          return false;
        }) || 'about';

      setActiveSection(current);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <LatticeworkBackground />
      <MouseGradient />
      <div className="mx-auto min-h-screen max-w-screen-xl px-6 py-12 font-sans md:px-12 md:py-16 lg:py-0">
        <a
          href="#about"
          className="absolute left-0 top-0 block -translate-x-full rounded bg-gold px-4 py-3 text-sm font-bold uppercase tracking-widest text-dark-navy focus-visible:translate-x-0"
        >
          Skip to Content
        </a>

        <div className="lg:flex lg:justify-between lg:gap-4">
          <Header activeSection={activeSection} />

          <main id="content" className="pt-24 lg:w-1/2 lg:py-24">
            <About />
            <Experience />
            <Skills />
            <Projects />
            <Footer />
          </main>
        </div>
      </div>
    </>
  );
}

export default App;
